import graphqlApiHandler from "../utils/graphql-api-handler";
import ReactQuerySingletonInstance from "./ReactQuerySingletonInstance";
const secrets = require('../../../public_keys.json');

const queryClient = ReactQuerySingletonInstance.getInstance(); 

const DEFAULT_STALE_TIME = secrets?.react_query_time_data?.DEFAULT_STALE_TIME || 0; 
const USER_DATA_STALE_TIME = secrets?.react_query_time_data?.USER_DATA_STALE_TIME ||0;
const SEED_DATA_STALE_TIME = secrets?.react_query_time_data?.SEED_DATA_STALE_TIME || 0;


export function querySeedData() {
  return queryClient.fetchQuery(['seed_data'], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    `query{
      dashboardThemes {
        titleColor,
        code,
        bgColor,
        descriptionBgColor,
        descriptionColor,
        highlightBgColor,
        fontName,
        railBgColor,
        borderColor,
        tagBgColor,
        tagTextColor,
        activeTitleColor,
        activeBgColor,
        activeBorderColor,
        inactiveTitleColor,
        inactiveBgColor,
        inactiveBorderColor,
        hoverBgColor,
        hoverTextColor
      },
      dashboardLayout {
        code,
        metaKeys
      },
      dashboardActions {
        code,
        metaKeys,
        metaDataStore
      }
    }
`
  ),{
     staleTime: SEED_DATA_STALE_TIME,
   });
}

export function queryFreemiumDashboardConfig(variables, queryCode, useDefaultStaleTime=false) {
  return queryClient.fetchQuery([`freemium_dashboard_info_${queryCode}`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    `query ($studentId: String!, 
      $isClassroomLinked: Boolean!, 
      $preferredLanguage: String!, 
      $daySinceStudentCreation:Int!, 
      $isClassroomStudent: Boolean!, 
      $loggedInGuardianType: String!, 
      $timeZone: String!
      $tabCode: String!,
      $dynamicHeader: Boolean!,
      $platform: String!,
      $countryCode: String!,
      $parentId: String!,
      $isPaidUser: Boolean!,
      $email: String!,
      $dashboardFeatures: [String!]!,
      $searchedKeywords: [String!]!,
      $searchedKeywordIds: [String!]!,
      $coqAnswers: [String!]!,
      $signupPageUrl: String!,
      $loginMode: String!,
      $startDateTimeUtc: String!,
      ) {
      
      userDashboard(studentId:$studentId){
    		dashboardCode
  		}

      dashboardElements(studentId: $studentId, isClassroomLinked:$isClassroomLinked, preferredLanguage:$preferredLanguage, daySinceStudentCreation:$daySinceStudentCreation, isClassroomStudent: $isClassroomStudent, loggedInGuardianType: $loggedInGuardianType, timeZone: $timeZone, tabCode: $tabCode, dynamicHeader: $dynamicHeader, platform: $platform, countryCode: $countryCode, parentId: $parentId, isPaidUser: $isPaidUser, email: $email, dashboardFeatures: $dashboardFeatures, searchedKeywords: $searchedKeywords, searchedKeywordIds: $searchedKeywordIds, coqAnswers: $coqAnswers, signupPageUrl: $signupPageUrl, loginMode: $loginMode, startDateTimeUtc: $startDateTimeUtc) {
        title,
        code,
        description,
        themeCode,
        eventCode,
        sectionTabName,
        elementLayout{
          code,
          metaKeys,
          metaDataStore
        },
        elementAction {
          code
          metaKeys,
          metaDataStore
        },
        children {
          title,
          description,
          themeCode,
          eventCode,
          eventCategory,
          code,
          eventCategory,
          ordering,
          elementAction {
            code,
            metaKeys,
            metaDataStore
          },
          elementLayout{
            code,
            metaKeys,
            metaDataStore
          },
          children {
            title,
            description,
            themeCode,
            eventCode,
            eventCategory,
            code,
            eventCategory,
            ordering,
            elementAction {
              code,
              metaKeys,
              metaDataStore
            },
            elementLayout{
              code,
              metaKeys,
              metaDataStore
            }
          }
        }
      }
    }
  `,
    variables
  ),{
     staleTime: useDefaultStaleTime ? DEFAULT_STALE_TIME : USER_DATA_STALE_TIME,
   });
}

export function queryImageLibrary(variables, queryCode, useDefaultStaleTime = false) {

  return queryClient.fetchQuery([`image_libray_${queryCode}`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    `query($thumbnailCodes: [String!]!, $platform: String!, $dashboardCode: String!){
       thumbnails(thumbnailCodes: $thumbnailCodes, dashboardCode:$dashboardCode, platform:$platform){
       cdnLink,
       platform,
       extension,
       thumbnailCode,
       thumbnailName
     }
    },
`,
    variables
  ),{
    staleTime: useDefaultStaleTime ? DEFAULT_STALE_TIME : USER_DATA_STALE_TIME,
  });
}


export function createUserDashboard(variables) {
  return queryClient.fetchQuery([`user_dashboard_creation_${variables?.attributes?.studentId}`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    `mutation($attributes: UserDashbordInput!){
      createUserDashboard(attributes: $attributes) {
        dashboardCode
      }
    },
`,
    variables
  ),{
     staleTime: DEFAULT_STALE_TIME,
   });
}

export function userDashboard(variables) {
  return queryClient.fetchQuery([`user_dashboard_${variables.studentId}`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    `query UserDashboard($studentId: String) {
      userDashboard(studentId: $studentId) {
        dashboardCode
      }
    }`,
    variables
  ),
  {
    staleTime: DEFAULT_STALE_TIME,
  });
}

export function liveClassQuery(variables){
  return queryClient.fetchQuery([`live_class_onboarding`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
  `query ($studentId: String!, $isPaidUser: Boolean!, $parentId: String!, $timeZone: String!, $email: String! , $dashboardFeatures: [String!]!, $startDateTimeUtc: String!) {
  liveClassElements(
    studentId: $studentId
    isPaidUser: $isPaidUser
    parentId: $parentId
    timeZone: $timeZone
    email: $email
    dashboardFeatures: $dashboardFeatures
    startDateTimeUtc: $startDateTimeUtc
  ) {
    title
    code
    description
    themeCode
    eventCode
    sectionTabName,
      ordering
    children {
      code
      description
      eventCode
      ordering
      sectionTabName
      sectionTabCode
      title
      children{
        code
        description
        title
        ordering
        elementLayout{
          code
          metaKeys
          metaDataStore
        }
        elementAction{
          code
          metaKeys
          metaDataStore
        }
        children{
          title
          code
          elementLayout{
            code
            metaKeys
            metaDataStore
          }
        	elementAction{
            code
            metaKeys
            metaDataStore
          }
        }
      }
    }
  }
}`,
    variables
  ),
  {
    staleTime: DEFAULT_STALE_TIME,
  });
}

// Fetches the class details for a student, including their permissions.
export function studentKlassDetails(variables) {
  return queryClient.fetchQuery([`studentklass_details_${variables.studentId}`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    // The GraphQL query to fetch the student's class details
    `query StudentKlassDetails($studentId: String!) {
      studentKlassDetails(studentId: $studentId) {
          permissions {
              allowGradeChange
          }
      }
  }`,
    variables
  ),
  {
    staleTime: DEFAULT_STALE_TIME, // The time (in milliseconds) after which the query data becomes stale
  });
}

export function fetchAvatarUrl(variables) {
  return queryClient.fetchQuery([`user_avatar_${variables.userId}`], graphqlApiHandler(`${GRAPHQL_SERVICE_URL}`,
    `query UserProfile($userId: String!) {
      userProfile(userId: $userId) {
        gamificationProfile
        { avatarUrl }
      }
    }`,
    variables
  ),
    {
      staleTime: DEFAULT_STALE_TIME,
    });
}
