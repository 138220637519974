export const VideoOnCross = 'https://player.vimeo.com/video/510317462';
export const HomePageVideo = 'https://vimeo.com/524161056';
export const DownloadAppPostTrialStartAppStore = 'https://apps.apple.com/app/apple-store/id672658828?pt=481348&ct=PostTrialStart_QRCode&mt=8';
export const DownloadAppDesktopDashboardAppStore = 'https://apps.apple.com/app/apple-store/id672658828?pt=481348&ct=DesktopDashboard_QRCode&mt=8';
export const DownloadAppParentDashboardAppStore = 'https://apps.apple.com/app/apple-store/id672658828?pt=481348&ct=ParentDashboard_CTA&mt=8';
export const DownloadAppPostTrialStartPlayStore = 'https://play.google.com/store/apps/details?id=com.splash.kids.education.learning.games.free.multiplication.reading.math.grade.app.splashmath&referrer=utm_source=Web&utm_medium=PostTrialStart&utm_term=QRCode'
export const DownloadAppDesktopDashboardPlayStore = 'https://play.google.com/store/apps/details?id=com.splash.kids.education.learning.games.free.multiplication.reading.math.grade.app.splashmath&referrer=utm_source=Web&utm_medium=DesktopDashboard&utm_term=QRCode'
export const DownloadAppParentDashboardPlayStore = 'https://play.google.com/store/apps/details?id=com.splash.kids.education.learning.games.free.multiplication.reading.math.grade.app.splashmath&referrer=utm_source=Web&utm_medium=ParentDashboard&utm_term=CTA'
export const TutoringPitchVideo = 'https://vimeo.com/424330183';
export const OddbodsVideo = 'https://player.vimeo.com/video/683739261?h=5be87457a0&speed=0&autoplay=1&app_id=122963';
export const mpsGameInviteSuscribeUrl = (studentId) => {
    return `/user/${studentId}/invite`
};
export const TutoringVideo = 'https://player.vimeo.com/video/715231609?h=1b9bcc6998&speed=0&autoplay=1&app_id=122963';
export const gameInviteWebSocketUrl = (studentId) => {
    return `${BASE_WEBSOCKET_GATEWAY_URL}/mps/ws-invites?student_id=${studentId}`;
};

export const PitchPageWithAgeGate = '/upgrade-pitch?ageGate=true';

const origin = location.origin;

export const getHostName = (origin) => {
    const host = location.host?.split('.')[0];
    switch (host) {
        case 'staging-1':
        case 'staging-uk':
        case 'staging-au': {
            return 'staging-1'
        }
        case 'staging-2': {
            return 'staging-2'
        }
        case 'staging-3': {
            return 'staging-3'
        }
        case 'staging-qa': {
            return 'staging-qa'
        }
        case 'f2': {
            return 'f2'
        }
        case 'preprod':
        case 'preprod-uk':
        case 'preprod-au': {
            return 'preprod'
        }
        default: {
            return 'production'
        }
    }
};

export const FlutterBuildCDNUrl = `${origin}/flutter_build/${getHostName(origin)}/build/web/index.html`;

export const FlutterBuildPitstopCDNUrl = `${origin}/flutter_pitstop_build/${getHostName(origin)}/build/web/index.html`;