var CallableMessages = /** @class */ (function () {
    //Singleton class
    function CallableMessages() {
        if (!CallableMessages._instance) {
            CallableMessages._instance = this;
        }
        return CallableMessages._instance;
    }
    /// Reloads flutter build
    CallableMessages.prototype.reloadFlutterBuild = function () {
        var _a, _b;
        window.flutterIframeWindow && ((_a = window.flutterIframeWindow) === null || _a === void 0 ? void 0 : _a.triggerReloadFlutter) && ((_b = window.flutterIframeWindow) === null || _b === void 0 ? void 0 : _b.triggerReloadFlutter());
        if (window.flutterScriptLoaded) { // only called when we have flutter build on viewing dom
            window.triggerReloadFlutter && window.triggerReloadFlutter();
        }
    };
    /// Open susbcription pop up
    CallableMessages.prototype.openSubscriptionPopup = function (reloadFlutterOnClose) {
        var _a, _b;
        if (reloadFlutterOnClose === void 0) { reloadFlutterOnClose = false; }
        window.flutterIframeWindow && ((_a = window.flutterIframeWindow) === null || _a === void 0 ? void 0 : _a.triggerShowSubscriptionPopup) && ((_b = window.flutterIframeWindow) === null || _b === void 0 ? void 0 : _b.triggerShowSubscriptionPopup(reloadFlutterOnClose));
        window.triggerShowSubscriptionPopup && window.triggerShowSubscriptionPopup(reloadFlutterOnClose);
    };
    CallableMessages.prototype.flutterRouteChange = function (route) {
        var _a, _b, _c;
        if (route === null || route === void 0 ? void 0 : route.length) {
            window.flutterIframeWindow && ((_a = window.flutterIframeWindow) === null || _a === void 0 ? void 0 : _a.triggerFlutterRouteChange) && ((_b = window.flutterIframeWindow) === null || _b === void 0 ? void 0 : _b.triggerFlutterRouteChange) && ((_c = window.flutterIframeWindow) === null || _c === void 0 ? void 0 : _c.triggerFlutterRouteChange(route));
            window.triggerFlutterRouteChange && window.triggerFlutterRouteChange(route);
        }
    };
    return CallableMessages;
}());
export default CallableMessages;
