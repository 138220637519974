import { isEligibleForDRSDashboard, hasAccessToTeacherFreemiumNoBlocker, getAvatarGamificationSegment } from "../../../utils/feature_mapping";

import { Store as StudentStore } from '../../../stores/student-dashboard';
import { addUniqueParamsToPath, getActiveTabCode, highestGradeCode, playPauseAudio } from '../../../utils/web-helper';
import { getCurrentCampaign, Campaigns, isSummerCampaignEnabled } from "../../../constant-data/campaign";
import { getABTestValue } from "../../../../js/utils/ab-tests";

// Multiplayer Game Launcher
function MultiplayerGameLauncher(gameDetails, closeHandler){
  const {courses } = StudentStore.getState();
  const gradeCode = highestGradeCode(courses);
  var {gameRoomId, gameRoomPlayerId, source, playzone_assigned, referrerPage} = gameDetails;
  var controller = 'multiplayer_game';
  var multiplayer = true;
  var launcherFunction = SPWidget.launchContentGame;
  var path = `/iframes/${controller}?`;
  const click_source = gameDetails.click_source;
  const topics_out_on_drs = isEligibleForDRSDashboard();
  const freemium_mode = hasAccessToTeacherFreemiumNoBlocker() ? 'timer' : 'count';
  const activeTabCode = gameDetails.active_tab_code ? gameDetails.active_tab_code : getActiveTabCode();
  let currentCampaign = getCurrentCampaign();
  if (currentCampaign == Campaigns.summerSpecial)
  {
    if(!isSummerCampaignEnabled()){
      currentCampaign = null;
    }
  }

  playPauseAudio();

  const gameCloseHandler = () => {
    playPauseAudio(false);
    closeHandler();
  }

  path += `game_room_id=${gameRoomId}&game_room_player_id=${gameRoomPlayerId}&source=${source}&playzone_assigned=${playzone_assigned}&click_source=${click_source}&topics_out_on_drs=${topics_out_on_drs}&freemium_mode=${freemium_mode}&grade_code=${gradeCode}`;

  if(currentCampaign){
    path += `&theme=${currentCampaign}`
  }

  if(topics_out_on_drs){
    path += `&from_tab=${activeTabCode} Tab`;
  }

  path = addUniqueParamsToPath(path, gameDetails.extra_param_for_launcher);  

  if (referrerPage) { // ADD THIS IN LAST ALWAYS as the WEB split url based on ?, referrerPage contains the page url so it sometimes can contain '?' used for query param and hence when WEB parse splitting by '?', the params after the referrerPage gets lost
    path += `&referrerPage=${referrerPage}`;
  }


  let soundEnabled = localStorage.getItem("flutter.sound_enabled");

  if(soundEnabled != null){
    path+= `&is_music_on=${soundEnabled}`;
  }

  let gamificationAbTestSegment = getAvatarGamificationSegment();
  
  if (gamificationAbTestSegment == 'Variant'){
    path+= `&gamification_v1=${gamificationAbTestSegment}`;
  }

  let gameHeaderAbTestSegment = getABTestValue("Parent","GameHeaderV1");

  if(gameHeaderAbTestSegment){
    path+= `&game_header_v1=${gameHeaderAbTestSegment}`;
  }

  launcherFunction({ path: path, closeHandler: gameCloseHandler, controller : controller, multiplayer : multiplayer});
}

//add click source
export default MultiplayerGameLauncher;